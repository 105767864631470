<template>
  <div id="app" v-loading="loading">
    <!-- 如果已经登录就显示首页不然跳转登录页面 -->
    <div v-if="isLogin">
      <el-header>
        <div class="top-right">
          <el-dropdown>
            <span class="el-dropdown-link">
              <label>{{name}}</label><i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <div>
                <!-- <el-dropdown-item><p @click="toProfile" class="drop-p"><i class="iconfont icon-profile"></i><span>个人资料</span></p></el-dropdown-item> -->
                <el-dropdown-item><p @click="logout" class="drop-p"><span>چېكىنىش </span><i class="iconfont icon-quit"></i></p></el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="top-left">
          <p class="dashboard-name"> دۇردانە ئارقا سۇپا باشقۇرۇش سېستىمىسى </p>
        </div>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-menu :unique-opened=true :default-active="$route.path">
            <el-submenu v-for="(item,index) in permissions" :key="index" :index="item.icon_class">
              <template slot="title">
                <i class="menu-icon iconfont" :class="item.icon_class"></i>
                <span>{{item.name}}</span>
              </template>
              <el-menu-item-group v-for="(child,index) in item.menus" :key="index">
                <el-menu-item :index="child.path" @click="getTags(child)">
                  <router-link :to="child.path">{{child.name}}</router-link>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-container>
          <el-main>
            <keep-alive>
              <router-view></router-view>
            </keep-alive>
          </el-main>
        </el-container>
      </el-container>

      <!-- profile-dialog -->
      <el-dialog
        title="个人资料修改"
        :visible.sync="profileDialogVisible"
        width="30%"
        :before-close="profileHandleClose">
        <div class="form-content">
          <el-form label-width="120px" :model="profileForm" ref="profileForm">
            <el-form-item label="用户姓名" prop="name">
              <el-input v-model="profileForm.name" placeholder="用户姓名"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="profileForm.phone" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item label="旧密码" prop="old_password">
              <el-input v-model="profileForm.old_password" :type="pwdType" placeholder="旧密码">
                <i slot="suffix" class="el-input__icon iconfont" :class="{'icon-close-eye':pwdType=='password','icon-eye':pwdType=='text'}" @click="viewPwd">
                </i>
              </el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
              <el-input v-model="profileForm.new_password" :type="pwdType" placeholder="新密码">
                <i slot="suffix" class="el-input__icon iconfont" :class="{'icon-close-eye':pwdType=='password','icon-eye':pwdType=='text'}" @click="viewPwd">
                </i>
              </el-input>
            </el-form-item>
            <el-form-item label="新密码确认" prop="new_password_confirmation">
              <el-input v-model="profileForm.new_password_confirmation" :type="pwdType" placeholder="新密码确认">
                <i slot="suffix" class="el-input__icon iconfont" :class="{'icon-close-eye':pwdType=='password','icon-eye':pwdType=='text'}" @click="viewPwd">
                </i>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" plain @click="closeProfileDialog('profileForm')">بىكار قىلىش</el-button>
          <el-button type="primary" plain @click="resetProfileForm('profileForm')">ئەسلىگە قايتۇرۇش</el-button>
          <el-button type="success" plain @click="submitProfileForm('profileForm')">مۇقىملاش</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .el-header{
    background-color: #5b93ff;
    line-height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    overflow: hidden;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    .top-left{
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      align-items: center;
      img{
        width: 30px;
        height: 25px;
        margin-right: 10px;
      }
      .dashboard-name{
        color: #fff;
        font-size: 16px;
      }
    }
    .top-right{
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .el-dropdown{
        color: #fff;  
      }
    }
  }
  .el-header{
    flex-direction: row;
  }
  .el-dropdown-menu{
    text-align: center;
  }
  .el-aside {
    background-color: #5b93ff;
    color: #fff;
    position: fixed;
    top: 60px;
    bottom: 0;
    right: 0;
    z-index: 99;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .el-main {
    color: #333;
    min-height: 890px;
    padding-right: 220px;
    padding-top: 80px;
  }
  .el-submenu{
    .el-menu-item {
      position: relative;
      >a{
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        padding-left: 0;
        padding-right: 40px;
      }
    }  
  }
  .active-link{
    color:#fff;
  }
  .el-menu{
    border-right: none;
    li{
      background-color: #5b93ff;
      color: #fff;
      text-align: right;
      direction: rtl;
      padding: 0 !important;
      .iconfont{
        color: #fff;
        padding-left: 4px;
      }
      &.is-active{
        background-color: #397dff;
        position: relative;
        &:before{
          content: "\e792";
          font-family: element-icons!important;
          speak: none;
          font-style: normal;
          font-weight: 400;
          position: absolute;
          right: -8px;
          font-size: 22px;
        }
      }
      &.el-submenu.is-active{
        &:before{
          content: "";          
        }
      }
    }
  }
  .is-opened{
    .el-menu{
      border-right: none;
      >li{
        background-color: #397dff;
        text-align: right;
        direction: rtl;
      }
    }
  }
  .el-dropdown-link label,.el-dropdown-link i{
    cursor: pointer;
  }
</style>

<script>
  var self;
  var timer;

  export default {
    activated(){
      self = this;
      self.name = localStorage.getItem("name");
      self.getPermissions(); //获取菜单以及权限
    },
    data () {
      return {
        name:"",
        permissions: [],
        noticeList:[],
        isLogin:false,
        profileDialogVisible:false,
        profileForm:{
          name:null,
          phone:null,
          old_password:null,
          new_password:null,
          new_password_confirmation:null
        },
        pwdType:"password",
        loading:false
      }
    },
    methods: {
      getTags(item){
        sessionStorage.setItem("curTags",JSON.stringify(item.tags));
      },
      getPermissions(){
        self.$fetch("admin/menus-permissions").then(response => {
          if (response.status == 200) {
            self.isLogin = true; //登录过了就可以获取菜单

            self.permissions = response.data.data;
            self.permissions.forEach(function(menu,index){
              menu.menus.forEach(function(subMenu,index){
                if(subMenu.path == '/permissions'){
                  self.$set(subMenu,'disabled',true);
                }
              });
            });

            if(self.$route.path == '/home'){
              self.$router.push({ path: "/index" });
            }

          } else{
            console.log(response.message);
            self.$message({
              message: err.response.data.message,
              type: 'warning'
            });
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          if (err.response.status == 401) {
            self.isLogin = false; //没有登录就跳转到登录页面
          }
        });
      },
      logout(){
        self.loading = true;
        self.$remove('admin/authorizations').then((response) => {
          console.log('-----------------');
          console.log(response);
          if(response.status == 204){
            self.$message({
              // message: response.data.message, //接口没有返回message 
              message: "已安全退出登录",
              type: "success",
              duration: 1500
            });
            self.$router.push({ path: "/login" });
          }else{
            console.log(response.message);
          }
          self.loading = false;
        }).catch(err => {
          console.log('××××××××××××××××');
          console.log(err.response);
          self.loading = false;
        });
      },
      toProfile(){
        self.profileDialogVisible = true;
        self.profileForm.name = localStorage.getItem("name");
        self.profileForm.phone = localStorage.getItem("phone");
      },
      profileHandleClose(done) {
        done();
        self.$refs.profileForm.resetFields();
        self.resetProfileForm("profileForm");
      },
      resetProfileForm(formName) {
        self.$refs[formName].resetFields();
      },
      closeProfileDialog(formName) {
        self.$refs[formName].resetFields();
        self.profileDialogVisible = false;
      },
      viewPwd(){
        if(self.pwdType == "text"){
          self.pwdType = "password";
        }else{
          self.pwdType = "text";
        }
      },
      submitProfileForm(profileForm){ //修改个人资料
        var data = {};
        if(self.profileForm.name != "" && self.profileForm.name != null){
          data.name = self.profileForm.name;
        }
        if(self.profileForm.phone != "" && self.profileForm.phone != null){
          data.phone = self.profileForm.phone;
        }
        if(self.profileForm.old_password != "" && self.profileForm.old_password != null){
          data.old_password = self.profileForm.old_password;
        }
        if(self.profileForm.new_password != "" && self.profileForm.new_password != null){
          data.new_password = self.profileForm.new_password;
        }
        if(self.profileForm.new_password_confirmation != "" && self.profileForm.new_password_confirmation != null){
          data.new_password_confirmation = self.profileForm.new_password_confirmation;
        }
        self.$patch('admin/profile/',data).then((response) => {
          if(response.status == 201){
            self.$message({
              message: response.data.message,
              type: "success",
              duration: 1200
            });
            if(self.profileForm.name != "" || self.profileForm.name != null){
              localStorage.setItem("name",self.profileForm.name); //如果名字改了，本地也改掉
              self.name = self.profileForm.name;
            }
            if(self.profileForm.new_password != "" || self.profileForm.new_password != null){ //如果改变密码了，就得重新登录
              self.$router.push({ path: "/login" });
            }
            self.closeProfileDialog(profileForm); //最后再ئېتىك对话框
          }else{
            self.$message({
              message: response.data.message,
              type: "warning",
              duration: 1500
            });
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      }
    }
  }

  window.getAllPermissions = function(){
    console.log('---getAllPermissions---');
    self.getPermissions();
  }
</script>